
import Button from 'components/Button'
import Image from 'components/Image'
import Text from 'components/Text'

const styles = {
    wrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
    innerWrapper: {
        boxShadow: '0px 5px 20px rgba(205, 213, 231, 0.7)',
        borderRadius: 10,
        margin: '30px 15px',
        padding: '30px 15px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        maxWidth: 450,
    },
}

const HomeUI = () => {
    return (
        <div style={styles.wrapper}>
            <div style={styles.innerWrapper}>
                <Image
                    src={'https://dwu26uvbjm2sv.cloudfront.net/mediakit.png'}
                    width={75}
                    height={75}
                    borderRadius={0}
                />
                <div style={{ height: 30 }}/>
                <Text
                    children={'Create an attractive influencer mediakit in minutes'}
                    size={'h5'}
                    weight={'bold'}
                />
                <div style={{ height: 30 }}/>
                <Text
                    children={'Land more brand deals with a shareable mediakit that updates your Instagram, Youtube and Tiktok analytics everyday.'}
                    size={'h6'}
                    weight={'normal'}
                    color={'text'}
                />
                <div style={{ height: 30 }}/>
                <Button
                    children={'Create Mediakit'}
                    style={{ padding: '10px 30px' }}
                    onDisclose={() => window.open('https://to.mysocial.io/mysocial', 'blank')}
                />
            </div>
        </div>
    )
}

export default HomeUI
